<template>
    <router-view />
</template>

<script>
export default {
    name: 'App',
};
</script>

<style>
@import './assets/styles.scss';
body {
    background: linear-gradient(
        90deg,
        rgba(47, 54, 64, 1) 23%,
        rgba(24, 27, 32, 1) 100%
    );
}

#app {
    text-align: center;
    margin-top: 60px;
}
</style>
