<template>
    <div>
        <HeaderLinks />
        <StarterPage />
    </div>
</template>

<script>
import HeaderLinks from '@/components/HeaderLinks';
import StarterPage from '@/components/StarterPage';

export default {
    components: {
        HeaderLinks,
        StarterPage,
    },
};
</script>
